import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledDivider = styled.hr`
  background-color: ${colors.core01};
  border: none;
  height: 3px;
`;

export const DividerContainer = styled.div``;

export const DividerHeading = styled.h2`
  ${fonts.headingMedium};
  text-align: center;
`;

export const DividerSubheading = styled.h2`
  color: ${colors.core01};
  ${fonts.headingMedium};
  text-align: center;
  margin-top: -${spacing(2)};
`;
