import React from 'react';
import {
  DividerContainer,
  DividerHeading,
  DividerSubheading,
  StyledDivider,
} from './SummedDivider.styles';

export type SummedDividerProps = {
  figure: string;
};

const SummedDivider: React.FC<SummedDividerProps> = ({ figure }) => (
  <>
    <StyledDivider />
    <DividerContainer>
      <DividerHeading>Total cover required</DividerHeading>
      <DividerSubheading>{figure}</DividerSubheading>
    </DividerContainer>
    <StyledDivider />
  </>
);

export default SummedDivider;
