import Feature from '@rsa-digital/evo-shared-components/components/Feature/Feature';
import { Tooltip } from '@rsa-digital/evo-shared-components/components/Form/Field/FieldInfo';
import TextInput from '@rsa-digital/evo-shared-components/components/Form/TextInput';
import React from 'react';
import { processImageAsset, unwrapSingleton } from 'helpers/csTypeProcessors';
import { CsAsset, CsIcon } from 'types/contentStack';
import { StyledField, TooltipWrapper } from './FeatureWithInput.styles';

export type FeatureWithInputProps = {
  feature: {
    dataCy: string;
    body: string;
    displayBackground: boolean;
    image: CsAsset | null;
    subtitle: string | null;
    title: string;
  };
  input: {
    label: string;
    id: string;
    name: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value: string | undefined;
  };
  tooltip: {
    body: string;
    buttonText: string;
    icon: [CsIcon];
  };
};

const FeatureWithInput: React.FC<FeatureWithInputProps> = ({
  feature,
  input,
  tooltip,
}) => (
  <Feature
    data-cy={feature.dataCy}
    body={feature.body}
    displayBackground={feature.displayBackground}
    image={processImageAsset(feature.image)}
    invertContent
    subtitle={feature.subtitle || ''}
    title={feature.title}>
    <StyledField displayBackground={feature.displayBackground} label={input.label}>
      <TextInput
        id={input.id}
        name={input.name}
        onChange={input.onChange}
        value={input.value}
        numbersOnly
      />
    </StyledField>
    <TooltipWrapper>
      <Tooltip
        body={tooltip.body}
        buttonText={{
          open: tooltip.buttonText,
          close: tooltip.buttonText,
        }}
        icon={unwrapSingleton(tooltip.icon)?.icon_code}
      />
    </TooltipWrapper>
  </Feature>
);

export default FeatureWithInput;
