import { graphql } from 'gatsby';
import React, { useState } from 'react';
import Layout from 'components/Layout';
import FeatureWithInput from 'components/ValueOfContents/FeatureWithInput';
import SummedDivider from 'components/ValueOfContents/SummedDivider';
import CtaBanners, { CtaBannersBlockProps } from 'components/blocks/CtaBanners';
import { FeatureBlockData } from 'components/blocks/Feature';
import { HeroWithMediaData } from 'components/blocks/HeroBanner';
import HeroWithMedia from 'components/blocks/HeroBanner/HeroWithMedia';
import { RichTextBlock } from 'components/blocks/RichText';
import { processPageMeta } from 'helpers/csTypeProcessors';
import { CsIcon, PageMeta } from 'types/contentStack';

type Props = {
  data: ValueOfContentsPageData;
};

type ValuesState = {
  living: string | undefined;
  kitchen: string | undefined;
  bedroom: string | undefined;
  bathroom: string | undefined;
  garage: string | undefined;
};

const ValueOfContentsPage: React.FC<Props> = ({ data }) => {
  const page = data.csValueOfContentsPage;
  const { meta, breadcrumbs } = processPageMeta(page.page_meta, page.url);

  const [values, setValues] = useState<ValuesState>({
    living: undefined,
    kitchen: undefined,
    bedroom: undefined,
    bathroom: undefined,
    garage: undefined,
  });

  const summedValues = Object.values(values)
    .map((val) => Number(val) || 0)
    .reduce((acc, curr) => acc + curr, 0);

  const nearestThousand = Math.ceil(summedValues / 1000) * 1000;

  const currencyValue = Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    maximumFractionDigits: 0,
  }).format(nearestThousand);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setValues({ ...values, [e.target.name]: e.target.value });

  return (
    <Layout
      url={page.url}
      pageMetadata={meta}
      breadcrumbs={breadcrumbs}
      locale={page.locale}>
      <HeroWithMedia
        hero_with_media={page.hero_with_media}
        showResponsiveVersion={page.show_new_hero}
      />
      <RichTextBlock rich_text={page.rich_text} />
      <FeatureWithInput
        feature={{
          dataCy: 'FeatureLivingAndDiningRooms',
          body: page.living_and_dining_rooms.feature_living_and_dining_rooms.body,
          displayBackground: true,
          image: page.living_and_dining_rooms.feature_living_and_dining_rooms.image,
          subtitle: page.living_and_dining_rooms.feature_living_and_dining_rooms.subtitle,
          title: page.living_and_dining_rooms.feature_living_and_dining_rooms.title,
        }}
        input={{
          label: page.living_and_dining_rooms.input_label,
          id: 'Living',
          name: 'living',
          onChange: handleInput,
          value: values.living,
        }}
        tooltip={{
          body: page.living_and_dining_rooms.tooltip.body_text,
          buttonText: page.living_and_dining_rooms.tooltip.button_text,
          icon: page.living_and_dining_rooms.tooltip.icon,
        }}
      />
      <FeatureWithInput
        feature={{
          dataCy: 'FeatureKitchen',
          body: page.kitchen.feature_kitchen.body,
          displayBackground: false,
          image: page.kitchen.feature_kitchen.image,
          subtitle: page.kitchen.feature_kitchen.subtitle,
          title: page.kitchen.feature_kitchen.title,
        }}
        input={{
          label: page.kitchen.input_label,
          id: 'Kitchen',
          name: 'kitchen',
          onChange: handleInput,
          value: values.kitchen,
        }}
        tooltip={{
          body: page.kitchen.tooltip.body_text,
          buttonText: page.kitchen.tooltip.button_text,
          icon: page.kitchen.tooltip.icon,
        }}
      />
      <FeatureWithInput
        feature={{
          dataCy: 'FeatureBedrooms',
          body: page.bedrooms.feature_bedrooms.body,
          displayBackground: true,
          image: page.bedrooms.feature_bedrooms.image,
          subtitle: page.bedrooms.feature_bedrooms.subtitle,
          title: page.bedrooms.feature_bedrooms.title,
        }}
        input={{
          label: page.bedrooms.input_label,
          id: 'Bedrooms',
          name: 'bedroom',
          onChange: handleInput,
          value: values.bedroom,
        }}
        tooltip={{
          body: page.bedrooms.tooltip.body_text,
          buttonText: page.bedrooms.tooltip.button_text,
          icon: page.bedrooms.tooltip.icon,
        }}
      />
      <FeatureWithInput
        feature={{
          dataCy: 'FeatureBathroom',
          body: page.bathroom.feature_bathroom.body,
          displayBackground: false,
          image: page.bathroom.feature_bathroom.image,
          subtitle: page.bathroom.feature_bathroom.subtitle,
          title: page.bathroom.feature_bathroom.title,
        }}
        input={{
          label: page.bathroom.input_label,
          id: 'Bathroom',
          name: 'bathroom',
          onChange: handleInput,
          value: values.bathroom,
        }}
        tooltip={{
          body: page.bathroom.tooltip.body_text,
          buttonText: page.bathroom.tooltip.button_text,
          icon: page.bathroom.tooltip.icon,
        }}
      />
      <FeatureWithInput
        feature={{
          dataCy: 'FeatureOutbuildingsAndGarages',
          body: page.outbuildings_and_garages.feature_outbuildings_and_garages.body,
          displayBackground: true,
          image: page.outbuildings_and_garages.feature_outbuildings_and_garages.image,
          subtitle:
            page.outbuildings_and_garages.feature_outbuildings_and_garages.subtitle,
          title: page.outbuildings_and_garages.feature_outbuildings_and_garages.title,
        }}
        input={{
          label: page.outbuildings_and_garages.input_label,
          id: 'Outbuildings',
          name: 'garage',
          onChange: handleInput,
          value: values.garage,
        }}
        tooltip={{
          body: page.outbuildings_and_garages.tooltip.body_text,
          buttonText: page.outbuildings_and_garages.tooltip.button_text,
          icon: page.outbuildings_and_garages.tooltip.icon,
        }}
      />
      <SummedDivider figure={currencyValue} />
      <RichTextBlock rich_text={page.rich_text_2} />
      <CtaBanners cta_banners={page.cta_banners} />
    </Layout>
  );
};

export default ValueOfContentsPage;

type TooltipType = {
  button_text: string;
  body_text: string;
  icon: [CsIcon];
};

type ValueOfContentsPageData = {
  csValueOfContentsPage: {
    title: string;
    url: string;
    locale: string;
    show_new_hero: boolean;
    hero_with_media: HeroWithMediaData;
    rich_text: { html: string; html_id?: string };
    living_and_dining_rooms: {
      feature_living_and_dining_rooms: FeatureBlockData;
      tooltip: TooltipType;
      input_label: string;
    };
    kitchen: {
      feature_kitchen: FeatureBlockData;
      tooltip: TooltipType;
      input_label: string;
    };
    bedrooms: {
      feature_bedrooms: FeatureBlockData;
      tooltip: TooltipType;
      input_label: string;
    };
    bathroom: {
      feature_bathroom: FeatureBlockData;
      tooltip: TooltipType;
      input_label: string;
    };
    outbuildings_and_garages: {
      feature_outbuildings_and_garages: FeatureBlockData;
      tooltip: TooltipType;
      input_label: string;
    };
    rich_text_2: { html: string; html_id?: string };
    cta_banners: CtaBannersBlockProps;
    page_meta: PageMeta;
  };
};

export const query = graphql`
  query($id: String!) {
    csValueOfContentsPage(id: { eq: $id }) {
      title
      url
      locale
      show_new_hero
      hero_with_media {
        ...HeroWithMedia
      }
      rich_text {
        ...RichTextBlock
      }
      living_and_dining_rooms {
        feature_living_and_dining_rooms {
          ...FeatureBlock
        }
        tooltip {
          button_text
          body_text
          icon {
            ...Icon
          }
        }
        input_label
      }
      kitchen {
        feature_kitchen {
          ...FeatureBlock
        }
        tooltip {
          button_text
          body_text
          icon {
            ...Icon
          }
        }
        input_label
      }
      bedrooms {
        feature_bedrooms {
          ...FeatureBlock
        }
        tooltip {
          button_text
          body_text
          icon {
            ...Icon
          }
        }
        input_label
      }
      bathroom {
        feature_bathroom {
          ...FeatureBlock
        }
        tooltip {
          button_text
          body_text
          icon {
            ...Icon
          }
        }
        input_label
      }
      outbuildings_and_garages {
        feature_outbuildings_and_garages {
          ...FeatureBlock
        }
        tooltip {
          button_text
          body_text
          icon {
            ...Icon
          }
        }
        input_label
      }
      rich_text_2 {
        ...RichTextBlock
      }
      cta_banners {
        ...CtaBannersBlock
      }
      page_meta {
        ...PageMeta
      }
    }
  }
`;
