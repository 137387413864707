import Field from '@rsa-digital/evo-shared-components/components/Form/Field';
import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { GridItemWrapper } from '@rsa-digital/evo-shared-components/components/Grid/GridItem/styles';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledField = styled(Field)<{ displayBackground?: boolean }>`
  color: ${colors.core01};
  margin-top: ${spacing(2)};
  ${GridItemWrapper} {
    background: ${({ displayBackground }) => (displayBackground ? '#eee7f2' : '')};
    outline: ${({ displayBackground }) => (displayBackground ? '1px solid #eee7f2' : '')};
  }
  ${GridItem} {
    width: 100%;
  }
  ${mediaQuery.tabletPortrait`
    width: 50%;
  `}
  width: 100%;
`;

export const TooltipWrapper = styled.div`
  margin-bottom: ${spacing(4)};
`;
